.k-dropdown-wrap > .k-input, .k-dropdown .k-dropdown-wrap .k-select, .k-list, .k-reset {
    background-color: #fff;
}
.icon-btn-li i {
    font-size: 20px;
}

.icon-btn-link i {
    font-size: 16px;
}
.icon-btn-li .trkcollink-a {
    padding: 0 5px;
    margin: 0 0 0 10px;
}
.tblBt {
    background: #ccc;
    margin: 0 10px 0 0;
    border-radius: 20px;
    padding: 4px 7px;
    font-size: 13px;
    color: #fff;
    opacity: .6;
}
.tblBt:hover {
    text-decoration:none;
    color:#fff;
}
.alert-point {
    color: #fff;
    padding: 0 5px;
    border-radius: 50px;
    font-size: 12px;
}

.custom-modal-window-width {
    max-width: 90% !important;
}

@media only screen and (min-width: 1181px) and (max-width: 1600px) {
    .trk-points .trk-lbl .lbl-row .lbl-col-p {
        font-size: 13px;
    }

    .trk-points .trk-lbl .lbl-row .lbl-col-h {
        font-size: 13px;
    }
}
th.dt-center, td.dt-center {
    text-align: center;
}

.trkListOffer .trkListOfferAd .deleteBtn {
    background: rgba(255, 255, 255, 0.8);
    padding: 5px 15px;
    border-radius: 50px;
}

    .trkListOffer .trkListOfferAd .deleteBtn:hover {
        text-decoration: none;
    }

.table thead,
.table th {
    text-align: left;
}
.blr-msg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

    .blr-msg h5 {
        background: rgba(255,255,255,.9);
        padding: 13px 30px;
        margin: 85px 0 0 0;
        height: 50%;
        display: flex;
        align-items: center;
        font-size: 13px;
    }

.disabledTab {
    pointer-events: none;
}

.notification {
    position: absolute;
    height: 15px;
    width: auto;
    min-width: 15px;
    border-radius: 50%;
    top: 0px;
    left: 9px;
    color: #fff;
    font-size: 9px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
    font-weight: bold;
}

.notifications {
    width: 300px;
}

.note-read {
    opacity: .6;
    background: rgba(0,0,0,.025);
}

.notifications h2 {
    font-size: 16px;
    padding: 10px;
    border-bottom: 1px solid #eee;
    color: #000;
}

    .notifications h2 span {
        color: #fff;
        background: #dc3545;
        width: 15px;
        min-width: 15px;
        height: 15px;
        padding: 2px 6px;
        border-radius: 50%;
        font-size: 11px;
    }

.notifications-item {
    display: flex;
    border-bottom: 1px solid #eee;
    padding: 6px 9px;
    margin-bottom: 0px;
    cursor: pointer
}

    .notifications-item:hover {
        background-color: #eee
    }

    .notifications-item img {
        display: block;
        width: 40px;
        height: 40px;
        margin-right: 9px;
        border-radius: 50%;
        margin-top: 2px
    }

    .notifications-item .text h4 {
        color: #333;
        font-size: 14px;
        margin-top: 3px;
    }

    .notifications-item .text p {
        color: #333;
        font-size: 12px
    }

    .notifications-item i {
        font-size: 20px;
        padding: 0 10px 0 0;
    }

.btn-trk a {
    margin: 0 5px;
    font-size: 17px;
}

.markAll {
    float: right;
    font-size: 11px;
    font-weight: 400;
    padding-top: 3px;
}
.blr-msg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

    .blr-msg h5 {
        background: rgba(255,255,255,.9);
        padding: 13px 30px;
        margin: 85px 0 0 0;
        height: 50%;
        display: flex;
        align-items: center;
        font-size:13px;
    }
.disabledTab {
    pointer-events: none;
}
.notification {
    position: absolute;
    height: 15px;
    width: auto;
    min-width: 15px;
    border-radius: 50%;
    top: 0px;
    left: 9px;
    color: #fff;
    font-size: 9px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
    font-weight: bold;
}

.notifications {
    width: 300px;
}

.note-read {
    opacity: .6;
    background: rgba(0,0,0,.025);
}

.notifications h2 {
    font-size: 16px;
    padding: 10px;
    border-bottom: 1px solid #eee;
    color: #000;
}

    .notifications h2 span {
        color: #fff;
        background: #dc3545;
        width: 15px;
        min-width: 15px;
        height: 15px;
        padding: 2px 6px;
        border-radius: 50%;
        font-size: 11px;
    }

.notifications-item {
    display: flex;
    border-bottom: 1px solid #eee;
    padding: 6px 9px;
    margin-bottom: 0px;
    cursor: pointer
}

    .notifications-item:hover {
        background-color: #eee
    }

    .notifications-item img {
        display: block;
        width: 40px;
        height: 40px;
        margin-right: 9px;
        border-radius: 50%;
        margin-top: 2px
    }

    .notifications-item .text h4 {
        color: #333;
        font-size: 14px;
        margin-top: 3px;
    }

    .notifications-item .text p {
        color: #333;
        font-size: 12px
    }

    .notifications-item i {
        font-size: 20px;
        padding: 0 10px 0 0;
    }

.btn-trk a {
    margin: 0 5px;
    font-size: 17px;
}
.markAll {
    float: right;
    font-size: 11px;
    font-weight: 400;
    padding-top:3px;
}
.tcolBtnDel {
    border: 1px solid #dc3545;
    border-radius: 20px;
    padding: 1px 11px 3px 11px;
    color: #dc3545;
}

.tcolBtnAssign {
    border: 1px solid #7174cc;
    border-radius: 20px;
    padding: 1px 11px 3px 11px;
    color: #7174cc;
}

/*swthBTN*/
.swt-btn .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    border-radius: 0;
}

    .swt-btn .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

.swt-btn .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

    .swt-btn .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

.swt-btn input:checked + .slider {
    background-color: #8183b9;
}
.swt-btn input:focus + .slider {
    box-shadow: 0 0 1px #357dad;
    -moz-box-shadow: 0 0 1px #357dad;
    -webkit-box-shadow: 0 0 1px #357dad;
}
.swt-btn input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
}
/* Rounded sliders */
.swt-btn .slider.round {
    border-radius: 34px;
}

    .swt-btn .slider.round:before {
        border-radius: 50%;
    }

.fltBoxRow-btn .swt-btn .slider:before {
    height: 16px;
    width: 16px;
}

.fltBoxRow-btn .swt-btn .switch {
    width: 40px;
    height: 24px;
}
/*swthBTN--end*/
.emailVerified
{
    font-size: 20px;
    position: absolute;
    top: 5px;
    right: 5px;
}
.loader-hover {
    z-index: 9999;
    float: left;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(255,255,255,.88);
    display: flex;
    align-items: center;
    justify-content: center;
}
button
{
    font-family: "Open Sans", sans-serif;
}

.numRight {
    min-width: 80px;
    max-width: 80px;
    text-align: right;
}
.textleft {
    min-width: 230px;
    max-width: 230px;
    text-align: left;
    float: left;
}



.k-dropdown-wrap > .k-input, .k-dropdown .k-dropdown-wrap .k-select, .k-list, .k-reset {
    background-color: #fff;
}

.icon-btn-li i {
    font-size: 20px;
}

.icon-btn-link i {
    font-size: 16px;
}

.icon-btn-li .trkcollink-a {
    padding: 0 5px;
    margin: 0 0 0 10px;
}

.tblBt {
    background: #ccc;
    margin: 0 10px 1px 0;
    border-radius: 20px;
    padding: 4px 7px;
    font-size: 13px;
    color: #fff;
    opacity: .6;
    float: left;
    display: flex;
    align-items: center;
}

    .tblBt:hover {
        text-decoration: none;
        color: #fff;
    }

.alert-point {
    color: #fff;
    padding: 0 5px;
    border-radius: 50px;
    font-size: 12px;
}

@media only screen and (min-width: 1181px) and (max-width: 1600px) {
    .trk-points .trk-lbl .lbl-row .lbl-col-p {
        font-size: 13px;
    }

    .trk-points .trk-lbl .lbl-row .lbl-col-h {
        font-size: 13px;
    }
}

th.dt-center, td.dt-center {
    text-align: center;
}

.trkListOffer .trkListOfferAd .deleteBtn {
    background: rgba(255, 255, 255, 0.8);
    padding: 5px 15px;
    border-radius: 50px;
}

    .trkListOffer .trkListOfferAd .deleteBtn:hover {
        text-decoration: none;
    }

.table thead,
.table th {
    text-align: left;
}

.blr-msg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

    .blr-msg h5 {
        background: rgba(255,255,255,.9);
        padding: 13px 30px;
        margin: 85px 0 0 0;
        height: 50%;
        display: flex;
        align-items: center;
        font-size: 13px;
    }

.disabledTab {
    pointer-events: none;
}

.notification {
    position: absolute;
    height: 15px;
    width: auto;
    min-width: 15px;
    border-radius: 50%;
    top: 0px;
    left: 9px;
    color: #fff;
    font-size: 9px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
    font-weight: bold;
}

.notifications {
    width: 300px;
}

.note-read {
    opacity: .6;
    background: rgba(0,0,0,.025);
}

.notifications h2 {
    font-size: 16px;
    padding: 10px;
    border-bottom: 1px solid #eee;
    color: #000;
}

    .notifications h2 span {
        color: #fff;
        background: #dc3545;
        width: 15px;
        min-width: 15px;
        height: 15px;
        padding: 2px 6px;
        border-radius: 50%;
        font-size: 11px;
    }

.notifications-item {
    display: flex;
    border-bottom: 1px solid #eee;
    padding: 6px 9px;
    margin-bottom: 0px;
    cursor: pointer
}

    .notifications-item:hover {
        background-color: #eee
    }

    .notifications-item img {
        display: block;
        width: 40px;
        height: 40px;
        margin-right: 9px;
        border-radius: 50%;
        margin-top: 2px
    }

    .notifications-item .text h4 {
        color: #333;
        font-size: 14px;
        margin-top: 3px;
    }

    .notifications-item .text p {
        color: #333;
        font-size: 12px
    }

    .notifications-item i {
        font-size: 20px;
        padding: 0 10px 0 0;
    }

.btn-trk a {
    margin: 0 5px;
    font-size: 17px;
}

.markAll {
    float: right;
    font-size: 11px;
    font-weight: 400;
    padding-top: 3px;
}

.blr-msg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

    .blr-msg h5 {
        background: rgba(255,255,255,.9);
        padding: 13px 30px;
        margin: 85px 0 0 0;
        height: 50%;
        display: flex;
        align-items: center;
        font-size: 13px;
    }

.disabledTab {
    pointer-events: none;
}

.notification {
    position: absolute;
    height: 15px;
    width: auto;
    min-width: 15px;
    border-radius: 50%;
    top: 0px;
    left: 9px;
    color: #fff;
    font-size: 9px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
    font-weight: bold;
}

.notifications {
    width: 300px;
}

.note-read {
    opacity: .6;
    background: rgba(0,0,0,.025);
}

.notifications h2 {
    font-size: 16px;
    padding: 10px;
    border-bottom: 1px solid #eee;
    color: #000;
}

    .notifications h2 span {
        color: #fff;
        background: #dc3545;
        width: 15px;
        min-width: 15px;
        height: 15px;
        padding: 2px 6px;
        border-radius: 50%;
        font-size: 11px;
    }

.notifications-item {
    display: flex;
    border-bottom: 1px solid #eee;
    padding: 6px 9px;
    margin-bottom: 0px;
    cursor: pointer
}

    .notifications-item:hover {
        background-color: #eee
    }

    .notifications-item img {
        display: block;
        width: 40px;
        height: 40px;
        margin-right: 9px;
        border-radius: 50%;
        margin-top: 2px
    }

    .notifications-item .text h4 {
        color: #333;
        font-size: 14px;
        margin-top: 3px;
    }

    .notifications-item .text p {
        color: #333;
        font-size: 12px
    }

    .notifications-item i {
        font-size: 20px;
        padding: 0 10px 0 0;
    }

.btn-trk a {
    margin: 0 5px;
    font-size: 17px;
}

.markAll {
    float: right;
    font-size: 11px;
    font-weight: 400;
    padding-top: 3px;
}

.tcolBtnDel {
    border: 1px solid #dc3545;
    border-radius: 20px;
    padding: 1px 11px 3px 11px;
    color: #dc3545;
}

.tcolBtnAssign {
    border: 1px solid #7174cc;
    border-radius: 20px;
    padding: 1px 11px 3px 11px;
    color: #7174cc;
}
/*swthBTN*/
.swt-btn .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    border-radius: 0;
}

    .swt-btn .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

.swt-btn .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

    .swt-btn .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

.swt-btn input:checked + .slider {
    background-color: #8183b9;
}

.swt-btn input:focus + .slider {
    box-shadow: 0 0 1px #357dad;
    -moz-box-shadow: 0 0 1px #357dad;
    -webkit-box-shadow: 0 0 1px #357dad;
}

.swt-btn input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
}
/* Rounded sliders */
.swt-btn .slider.round {
    border-radius: 34px;
}

    .swt-btn .slider.round:before {
        border-radius: 50%;
    }

.fltBoxRow-btn .swt-btn .slider:before {
    height: 16px;
    width: 16px;
}

.fltBoxRow-btn .swt-btn .switch {
    width: 40px;
    height: 24px;
}
/*swthBTN--end*/
.mx-th-tag {
    min-width: 80px;
    max-width: 160px;
    position: relative;
}

    .mx-th-tag:hover .mx-td-spn {
        max-height: 700px;
        white-space: normal;
        -webkit-transition: max-height 2s ease-in-out;
        -moz-transition: max-height 2s ease-in-out;
        -ms-transition: max-height 2s ease-in-out;
        -o-transition: max-height 2s ease-in-out;
        transition: max-height 2s ease-in-out;
    }

.mx-td-spn {
    max-height: 20px;
    text-overflow: ellipsis;
    max-width: 100%;
    min-width: 90px;
    white-space: nowrap;
    cursor: default;
    overflow: hidden;
    -webkit-transition: max-height 1.5s cubic-bezier(0,1.05,0,1);
    -moz-transition: max-height 1.5s cubic-bezier(0,1.05,0,1);
    -ms-transition: max-height 1.5s cubic-bezier(0,1.05,0,1);
    -o-transition: max-height 1.5s cubic-bezier(0,1.05,0,1);
    transition: max-height 1.5s cubic-bezier(0,1.05,0,1);
}


.disabledbtn {
    cursor: not-allowed;
    opacity: 0.4;
}

.demoSwitch {
    display: flex;
    align-items: center;
    float: right;
}

.mx-td-dec {
    color: #999;
    font-size: 12px;
}

.mx-td-plan {
    color: #000;
}

/*.k-calendar > .k-header .k-link{
   
}

 .k-calendar .k-header {
    color: inherit;
    background-color: #324191;
    box-shadow: none;
    text-transform: uppercase;
}*/
.resellerAssigned {
    margin: 0 !important;
    font-size: 16px;
    text-decoration: none;
}

.cursor-default {
    cursor: default;
}

.trk-points .trk-lbl .lbl-row .lbl-col-p {
    min-height: 40px;
    display: flex;
    align-items: center;
}

.trk-list .trk-list-col .tcolBtn {
    font-size: 12px;
    padding: 1px 9px 3px 9px;
    text-align: center;
}

.flex-wrap-nowrap {
    flex-wrap: nowrap;
}

.trkCol-p2 {
    word-break: break-all;
    word-wrap: break-word;
    white-space: pre-wrap;
}
/*.trk-container .trk-inr .trk-row .trkCol .trkCol-p {
    word-break: break-all;
}*/

.viewMsg {
    float: left;
    width: 100%;
}

.viewMsgRow {
    float: left;
    width: 100%;
    padding: 5px 0 0 0;
    font-size: 14px;
    color: #333;
}

.viewMsgTrk {
    float: left;
    padding: 5px 0 7px 0;
}

.viewMsgTrk-h {
    min-width: 170px;
    font-size: 14px;
    color: #999;
    padding: 0 0 5px 0;
}

.viewMsgTrk-p {
    font-size: 14px;
    float: left;
    padding: 0;
    width: 100%;
}

.viewMsgTrk-spn {
    float: left;
    width: 100%;
    border-left: 1px solid #ddd;
    padding: 0 0 0 10px;
    margin: 0 0 10px 0;
}

.viewMsgT {
    font-weight: 600;
    font-size: 16px;
    border-bottom: 2px solid #eee;
    float: left;
    min-width: 60px;
}

.viewMsgSection {
    float: left;
    width: 100%;
    padding: 0 0 30px 0;
}

.tabs-card .nav .nav-item {
    font-size: 14px;
    margin: 0 5px 0 20px;
}

    .tabs-card .nav .nav-item a {
        margin: 0;
        padding: 16px 0 16px 0;
    }

.stpTrk {
    background: #999;
    width: 20px;
    height: 20px;
    float: left;
    border-radius: 50px;
    margin: 0 2px 0 0;
    color: #fff;
    font-size: 14px;
}

.nav-tabs .nav-link.active .stpTrk {
    background: #6264a7;
}

.cursor-pointer {
    cursor: pointer;
}

.text-underline {
    text-decoration: underline;
}

.break-word {
    word-wrap: break-word !important;
}

.trk-container .trk-inr .trk-row.edit-row .trkCol .trkCol-p:hover {
    border: 2px solid #aaa !important;
    outline: none;
    border-radius: 5px !important;
}

.trk-container .trk-inr .trk-row .trkCol.edit-row .trkCol-p:hover {
    border: 2px solid #aaa !important;
    outline: none;
    border-radius: 5px !important;
}

.trk-container .trk-inr .add-edit-row .trkCol .trkCol-p {
    border: 2px solid #aaa !important;
    outline: none;
    border-radius: 5px !important;
}
.k-checkbox {
    height: 16px;
    width: 16px;
    &:checked::before {
      transform: scale(1.2) translate(-44%, -44%);
    }
  }
  .k-checkbox {
    background: transparent;

    &:checked {
      border-color: rgb(98, 100, 167);
      background-color: rgb(98, 100, 167);
    }
    &:hover,
    &:focus {
      border-color: rgb(98, 100, 167);
    }
  }
  .k-header .k-checkbox {
    background: transparent;
    border-color: #252423;
    &:checked {
      border-color: #252423;
      background-color: #252423;
    }
    &:hover,
    &:focus {
      border-color: 252423;
    }
  }

  .formCardSettings {
    .dropdownSection {
      display: grid;
      grid-template-columns: calc(50% - 10px) calc(50% - 10px);
      grid-column-gap: 20px;
  
      > div {
        width: 100%;
      }}}

      .chip {
        background: #95999c;
        
      }


      $accent: #6264a7;
      $primary: #6264a7;
      
      // typography
      $roboto: "Roboto", Helvetica, Arial, sans-serif;
      $ubunto: "Ubuntu", sans-serif;
      
      $font-weight-200: 200;
      
      // colors
      $white: #fff;
      $white-50: rgba(255, 255, 255, 0.5);
      $text-base: #252423;
      $text-light: #252423;
      $border-base: #ddd;
      $active-state-base: #6264a7;
      $hover-bg: #e6e6e6;
      $negative-value: #c4314b;
      $positive-value: #237b4b;
      $body-base-bg: rgba(236, 238, 239, 0.5);
      
      // margins
      $margin-sm: 4px;
      
      // fonts
      $font-size-default: 16px;
      
      // header
      $header-height: 140px;
      $header-title-padding: 0.5rem;
      $profile-image-width: 58px;
      $profile-image-height: 58px;
      $profile-image-margin-top: 2rem;
      
      // footer
      $footer-padding: 10px;
      $footer-height: 43px;
      $footer-font-size: $font-size-default;
      $progress-logo-height: 20px;
      $progress-logo-width: 100px;
      
      // dropdownlist
      $dropdownlist-font-size: $font-size-default;
      $dropdownlist-item-selected-bg: #e9ecef;
      $dropdownlist-item-selected-text: $active-state-base;
      
      $dropownlist-add-new-padding: 8px;
      $dropownlist-add-new-width: 125px;
      $dropownlist-add-new-height: 40px;
      
      // grid
      $grid-header-subtitle: 13px;
      $grid-row-selection-bg: #6264a7;
      $grid-cell-positive-color: $positive-value;
      $grid-cell-negative-color: $negative-value;
      $grid-sorting-icon-margin: $margin-sm;
      $grid-sorting-icon-right-position: 20px;
      
      // buttons
      $nav-button-width: 150px;
      $nav-button-active-bg: $active-state-base;
      
      // badge
      $badge-padding: 10px;
      $badge-height: 22px;
      
      // heatmap
      $heatmap-base-bg: $body-base-bg;
      .highlightOption {
        background: #6264a7 !important;
        
      } 
      .option:hover {
        background: #6264a7 !important;
        
      }    
      .k-upload-status-total{
        display: none !important;
      }
      .ui-box .ui-input__icon {
        display: none !important;
      }
      
      .ui-box .ui-input__input{
        width: 100% !important;
      }