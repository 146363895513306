.login-container-msg {
    width: 50%;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.innerlogin-msg {
    width: 100%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 99;
    float: left;
    background-color: white;
    color: #333;
    padding: 25px;
    border-radius: 4px;
}

.notification-msg {
    text-align: center;
    float: left;
    width: 100%;
}

.notification-msg-i {
    float: left;
    width: 100%;
    text-align: center;
    padding: 20px 0 15px 0;
}

    .notification-msg-i i {
        font-size: 60px;
        color: #99b1d6;
    }

.notification-msg-h {
    font-size: 24px;
    padding: 0 0 5px 0;
}

.notification-msg-p {
    font-size: 15px;
    padding: 0 0 10px 0;
}

.notification-msg-btn {
    padding: 15px 0 20px 0;
}

    .notification-msg-btn .btn {
        padding: 8px 20px 8px 20px;
        font-size: 15px !important;
    }